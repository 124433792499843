
import React from "react";
import Table from "../tables/Table";
import TableHeadItem from "../tables/TableHead";
import { Affix } from "antd"
import PayoutRow from "../tables/PayoutRow";

//import { useGetApprovedVendorsQuery } from "../redux/user";
const PayoutList=({data})=>{
    const theadData = ["Vendor",'Amount', "Method", "Reference", 'Date','Action'];
//    const{ data,isLoading}= useGetApprovedVendorsQuery()
   // console.log(data&&data)
    return (
        <div className="table-head-container">
                     <Affix offsetTop={200} className="">

        <div className="table-head">
          {theadData.map((h) => {
            return <TableHeadItem key={h} item={h} />;
          })}
        </div>
        </Affix>
        <div className="reqbody">
        {data.map((item) => {
                    
                    return ( <PayoutRow key={item.id} data={item} />);
                })} 
        </div>
      </div>
      
    );
}

export default PayoutList