import React, { Children } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Layout, Menu, theme,Affix } from 'antd';
import usergroup from '../assets/images/users.png'
import group2 from '../assets/images/Group2.png'
import money from '../assets/images/money.png'
import setting from '../assets/images/settings.png'
import user from '../assets/images/user.png'
import voucher from '../assets/images/voucher@2x.png'
import cash from '../assets/images/cash.png'
import wallet from '../assets/images/wallet.png'
import report from '../assets/images/report.png'
import Bell from "../assets/svgs/bell.svg"
import { ReactComponent as Logo } from "../assets/svgs/pamp_logo.svg";
import { FileOutlined, KeyOutlined,  UserOutlined, ShopOutlined,SettingFilled, BankOutlined, MessageOutlined, SettingOutlined, DashboardOutlined,BellOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
function Sidebar(){
    const [collapsed, setCollapsed] = React.useState(false);
    const navigate= useNavigate()
  const routes = ['dashboard', 'dashboard/vendors', 'vendors', 'reviews', 'users', 'bookings', 'services', 'coupons', 'subscriptions', "packages", "wallets", "vendor_payouts", "refunds", "send-notifications", "availability", "gallery", "earnings", "employees", 'settings', 'reports',"influencers","categories"]
    const { Sider } = Layout;
    function getItem(label, key, icon, children,route) {
  
        return {
          key,
          icon,
          children,
          label,
          route
        };
      }
    const items = [
        getItem('Dashboard', '0',<img src={group2} style={{width:10,}}/>,),
        getItem('Admin', '16', <img src={user} style={{ width: 10, }} />, [
          getItem('Earnings', '16'),
          getItem('Employees', '17'),
          
        ]),
        getItem('Vendors', '1', <img src={usergroup} style={{width:10,}}/>, [
          getItem('List', '2'),
          getItem('Reviews', '3'),
        ]),
        getItem('Users', 'sub1',  <img src={user} style={{width:10,}}/>, [
          getItem('Lists', '4'),
          getItem('Influencers', '20'),
          getItem('Bookings', '5'),
      
        ]),
        getItem('Coupons', '7',<img src={voucher} style={{width:10,}}/>),
         getItem('Categories', '21', <img src={voucher} style={{ width: 10, }} />),

      getItem('Subcription', 'sub2', <img src={money} style={{ width: 10, }} />,[
          getItem('Lists', '8'),
          getItem('Packages', '9'),
      
        ]),
        getItem('Payment', 'sub3',<img src={cash} style={{width:10,}}/>,[
          getItem('Vendor Payouts', '11'),
          getItem('Refunds', '12'),
      
        ]),
        getItem('Wallet', '10', <img src={wallet} style={{width:10,}}/>),
        getItem('Report', '19', <img src={report} style={{width:10,}}/>),
        getItem('Notification', '13', <img src={Bell} style={{width:10,}}/>),
        getItem('Settings', '18', <img src={setting} style={{width:10,}}/>),
      ];

    return(

<div className='fixed'>
        <Sider 
        width={260}
        
        className='admin-sidebar  no-scrollbar'
         >     

         <div className='bg-darkgreen fixed' style={{ margin: "auto", marginBottom: 20,width:260,zIndex:100, height:  150,borderRadius:20,paddingBottom:20 }}> 
           <Logo style={{margin:'auto',width:40,marginTop:30}}/>
          </div>

          <Menu  theme="dark" defaultSelectedKeys={['0']} mode="inline" items={items} onClick={({key})=>{navigate(routes[key])}} style={{ background:'#00463C',gap:30,paddingTop:150}}/>
        </Sider>
        </div>
    )
}

export default Sidebar